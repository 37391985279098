import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #ccd2e3;
  border: ${({ added }) => (added ? "1px solid #33498E" : "1px solid #CCD2E3")};
  box-shadow: ${({ added }) =>
    added ? "0px 4px 20px rgba(0, 0, 0, 0.1)" : "none"};
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  background: ${({ headingColor }) =>
    headingColor ? headingColor : "#001C72"};
  font-family: "Neuzeit Grotesk", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #001c72;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px dashed #99a4c7;
`;

export const Container = styled.div`
  position: relative;
  padding: 20px;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  padding: 12px 20px;
  margin: 12px auto;
  border-radius: 50px;
  background-color: ${({ added }) => (added ? "#f2d5d8" : "#e3eccc")};
  color: ${({ added }) => (added ? "#bc2e3e" : "#001c72")};
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;

  & > svg {
    margin: 0 8px;
    transition: transform 0.2s ease-in-out;
    transform: ${({ added }) => (added ? "rotate(45deg)" : "rotate(0)")};

    & > path {
      fill: ${({ added }) => (added ? "#bc2e3e" : "#001c72")};
    }
  }

  @media (min-width: 999px) {
    &:hover {
      background-color: ${({ added }) => (added ? "#bc2e3e" : "#289901")};
      color: #ffffff;

      & > svg {
        & > path {
          fill: #ffffff;
        }
      }
    }
  }

  & > span {
    margin-top: 2px;
  }
`;

export const ButtonText = styled.span`
  width: 100%;
  font-family: "Neuzeit Grotesk", sans-serif;
  text-align: center;
  line-height: 160%;
  letter-spacing: 0.16em;
`;

export const NoChildren = styled.div`
  padding: 50px;
  text-align: center;
  font-style: italic;
  font-size: 12px;
`;
